const datesToParse = Array.from(document.getElementsByClassName("parse-date") as HTMLCollectionOf<HTMLElement>);

datesToParse.forEach(d => {
    const startDateStr = d.dataset.dateStart;
    const endDateStr = d.dataset.dateEnd;

    const startDate = getDateFromString(startDateStr);
    const endDate = getDateFromString(endDateStr);

    const includeTimes = d.dataset.includeTimes == "true";

    if (startDate && endDate) {
        console.log(includeTimes)
        const dateStr = outputDateString(startDate, endDate, { show: includeTimes, format: "12", abbreviate: true });
        d.innerHTML = dateStr;
    }

})

function getDateFromString(dateStr: string): Date | null {
    if (Date.parse(dateStr) !== NaN) {
        return new Date(dateStr);
    }
    return null;
}

type TimeOptions = {
    show: boolean
    format: "24" | "12"
    abbreviate: boolean
}

function outputDateString(start: Date, end: Date, timeOptions: TimeOptions): string {
    if (start.getFullYear() === end.getFullYear()) {
        if (start.getMonth() === end.getMonth()) {
            if (start.getDate() === end.getDate()) {
                return `
                    ${timeOptions.show ? `${getSameDayTimeStr(start, end)}` + "," : ""} ${getDayName(start)} ${getDayNumStr(start)} 
                    ${getMonth(start)} ${start.getFullYear()}
                `;
            } else {
                return `
                    ${timeOptions.show ? getTimeStr(start, timeOptions.abbreviate, timeOptions.format) + "," : ""} ${getDayName(start)} ${getDayNumStr(start)} - 
                    ${timeOptions.show ? getTimeStr(end, timeOptions.abbreviate, timeOptions.format) + "," : ""} 
                    ${getDayName(end)} ${getDayNumStr(end)} ${getMonth(start)} ${start.getFullYear()}
                `;
            }
        } else {
            return `
                ${timeOptions.show ? getTimeStr(start, timeOptions.abbreviate, timeOptions.format) + "," : ""} 
                ${getDayName(start)} ${getDayNumStr(start)} ${getMonth(start)} - 
                ${timeOptions.show ? getTimeStr(end, timeOptions.abbreviate, timeOptions.format) + "," : ""} 
                ${getDayName(end)} ${getDayNumStr(end)} ${getMonth(end)} ${start.getFullYear()}
            `;
        }
    } else {
        return `
            ${timeOptions.show ? getTimeStr(start, timeOptions.abbreviate, timeOptions.format) + "," : ""} 
            ${getDayName(start)} ${getDayNumStr(start)} ${getMonth(start)} ${start.getFullYear()} - 
            ${timeOptions.show ? getTimeStr(end, timeOptions.abbreviate, timeOptions.format) + "," : ""} 
            ${getDayName(end)} ${getDayNumStr(end)} ${getMonth(end)} ${end.getFullYear()}
        `;
    }
}

function outputTimeString(start: Date, end?: Date): string {
    if (end) {
        return `${getTimeStr(start)} - ${getTimeStr(end)}`;
    } else {
        return getTimeStr(start);
    }
}

export function parseFullDate(date: Date, includeMonth = true, includeYear = true): string {
    return `${getDayName(date)}, ${getDayNumStr(date)}${includeMonth ? " " + getMonth(date) : ""}${includeYear ? ` ${date.getFullYear()}` : ""}`;
}

export function getTimeObject(date: Date) {
    return {
        hour: date.getHours(),
        minute: date.getMinutes(),
        minutesString: getAbbreviatedMinutes(date.getMinutes()),
        ampm: date.getHours() >= 12 ? "pm" : "am"
    }
}

function getSameDayTimeStr(start: Date, end: Date, abbreviate = true): string {
    const startTime = getTimeObject(start);
    const endTime = getTimeObject(end);
    let startHours = startTime.ampm == "pm" ? startTime.hour - 12 : startTime.hour;
    let endHours = endTime.ampm == "pm" ? endTime.hour - 12 : endTime.hour;
    if (startTime.ampm == endTime.ampm) {
        return `${startHours}${startTime.minutesString} - ${endHours}${endTime.minutesString}${startTime.ampm}`;
        
    } else {
        return `${startTime.hour}:${startTime.minute < 10 ? "0" + startTime.minute : startTime.minute} ${startTime.ampm} - ${endTime.hour}:${endTime.minute < 10 ? "0" + endTime.minute : endTime.minute} ${endTime.ampm}`;
    }
}

function getAbbreviatedMinutes(minutes: number): string {
    console.log(minutes);
    if (minutes == 0) {
        return "";
    }
    if (minutes < 10) {
        return ":0" + minutes;
    } else {
        return ":" + minutes.toString();
    }
}

export function getTimeStr(date: Date, abbreviate = false, format: "24" | "12" = "24"): string {
    let timeObj = getTimeObject(date);
    if (format == "12") {
        if (abbreviate) {
            if (timeObj.minute == 0) {
                return `${date.getHours() % 12 == 0 ? "12" : date.getHours() % 12}${date.getHours() < 12 ? "a" : "p"}m`;
            }
        }
    }

    let minutes = timeObj.minute.toString();
    if (timeObj.minute < 10) {
        minutes = "0" + minutes;
    }
    return `${date.getHours()}:${minutes}`;
}

export function getTitleDateString(startDate: Date, endDate: Date) {
    let includeMonth = true;
    let includeYear = true;
    let includeDate = true;
    if (startDate.getFullYear() == endDate.getFullYear()) {
        includeYear = false;
        if (startDate.getMonth() == endDate.getMonth()) {
            includeMonth = false;
            if (startDate.getDay() == endDate.getDay()) {
                includeDate = false;
            }
        }
    }

    return `${includeDate ? `${parseFullDate(startDate, includeMonth, includeYear)} - ` : ""}${parseFullDate(endDate)}`;
}

export function getMonth(date: Date) {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    return months[date.getMonth()];
}

export function getDayNumStr(date: Date) {
    let numStr = date.getDate().toString();
    if (numStr[0] == "1" && numStr.length == 2) {
        numStr += "th"
    } else if (numStr[numStr.length - 1] == "1") {
        numStr += "st";
    } else if (numStr[numStr.length - 1] == "2") {
        numStr += "nd";
    } else if (numStr[numStr.length - 1] == "3") {
        numStr += "rd";
    } else {
        numStr += "th";
    }
    return numStr;
}

export function getDayName(date: Date) {
    switch (date.getDay()) {
        case 0:
            return "Sunday";
        case 1:
            return "Monday";
        case 2:
            return "Tuesday";
        case 3:
            return "Wednesday";
        case 4:
            return "Thursday";
        case 5:
            return "Friday";
        case 6:
            return "Saturday";
        default:
            return "";
    }
}


export function parseDate(date: Date) {
    return `${getMonth(date)} ${getDayNumStr(date)}, ${date.getFullYear()}`;
}